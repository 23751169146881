import {
  budgetOptions,
  exteriorOptions,
  helpNeededOptions,
  landscapingOptions,
  timeFrameOptions,
  uniqueYardOptions,
  yardSelectionOptions,
  yardSizeOptions,
} from "../pages/designProfile/util/quizPage/quizSelections";

import {
  BUDGET_PATH,
  CONTACT_INFO_PATH,
  CREATE_ACCOUNT_PATH,
  FIND_YOUR_PACKAGE_PATH,
  HELP_NEEDED_PATH,
  HOME_ADDRESS_PATH,
  LOCATION_FOUND_PATH,
  LOCATION_LOADING_PATH,
  RECOMMENDED_PACKAGE_PATH,
  TIME_FRAME_PATH,
  UNIQUE_YARD_PATH,
  WISH_LIST_PATH,
  YARD_SELECTION_PATH,
  YARD_SIZE_PATH,
} from "../pages/designProfile/constants/paths";
import { DesignProfileNavigation } from "../types/DesignProfile";
import { DESIGN_PROFILE_VERSION_9 } from "../util/constants/designProfileVersionIds";

// DO NOT EDIT OLD VERSIONS. VERSIONS #'S ARE STORED IN FIRESTORE
// EACH SUBSEQUENT VERSION NEEDS TO INCREMENT IN VERSION # AND NEEDS
// IT'S OWN FILE IN src/designProfileVersions

// Version 9: Relocate e-mail collection prompt to beginning of flow
// https://yardzen.atlassian.net/browse/GROW-756
export const designProfileVersion9: DesignProfileNavigation = {
  versionId: DESIGN_PROFILE_VERSION_9,
  paths: [
    {
      path: FIND_YOUR_PACKAGE_PATH,
      component: "DesignProfileLandingPage",
      title: "Which Yardzen design package is right for your space?",
      button_text: "GET STARTED",
      alt_button_text: null,
      next: CONTACT_INFO_PATH,
      previous: null,
      header_page: 0,
    },
    {
      path: CONTACT_INFO_PATH,
      component: "DesignProfileEmailPhoneInputPage",
      title: "Let’s get started!",
      subtitle:
        "Sign up now and you'll receive a free expedited upgrade code for your first order.*",
      button_text: "Next",
      alt_button_text: null,
      next: HOME_ADDRESS_PATH,
      previous: FIND_YOUR_PACKAGE_PATH,
      header_page: 0,
    },
    {
      path: HOME_ADDRESS_PATH,
      component: "AddressInputPage",
      title: "Where is your outdoor space?",
      button_text: "Submit",
      alt_button_text: null,
      next: LOCATION_LOADING_PATH,
      previous: CONTACT_INFO_PATH,
      header_page: 1,
      completion_number: 1,
    },
    {
      path: LOCATION_LOADING_PATH,
      component: "MapLoadingPage",
      title: "We're digging into",
      button_text: null,
      alt_button_text: null,
      next: LOCATION_FOUND_PATH,
      previous: HOME_ADDRESS_PATH,
      header_page: 2,
    },
    {
      path: LOCATION_FOUND_PATH,
      component: "AddressConfirmationPage",
      title: "We found your property!",
      button_text: "THAT'S IT",
      alt_button_text: "THAT'S NOT IT",
      next: HELP_NEEDED_PATH,
      previous: HOME_ADDRESS_PATH,
      header_page: 2,
    },
    {
      path: HELP_NEEDED_PATH,
      component: "DesignProfileQuizPage",
      title: "What do you need help with?",
      button_text: "NEXT",
      alt_button_text: null,
      next: YARD_SELECTION_PATH,
      previous: HOME_ADDRESS_PATH,
      selections: {
        default: helpNeededOptions,
      },
      multiple_selections: true,
      required: false,
      drop_down: false,
      header_page: 2,
      completion_number: 2,
      quiz_step_number: 0,
    },
    {
      path: YARD_SELECTION_PATH,
      component: "DesignProfileQuizPage",
      title: "Which areas need help?",
      button_text: "NEXT",
      alt_button_text: null,
      next: YARD_SIZE_PATH,
      previous: HELP_NEEDED_PATH,
      selections: {
        default: yardSelectionOptions,
      },
      multiple_selections: false,
      required: true,
      drop_down: false,
      header_page: 2,
      completion_number: 3,
      quiz_step_number: 1,
    },
    {
      path: YARD_SIZE_PATH,
      component: "DesignProfileQuizPage",
      title: "What is the size of your property?",
      button_text: "NEXT",
      alt_button_text: null,
      next: WISH_LIST_PATH,
      previous: YARD_SELECTION_PATH,
      selections: {
        default: yardSizeOptions,
      },
      multiple_selections: false,
      required: true,
      drop_down: false,
      header_page: 2,
      completion_number: 4,
      quiz_step_number: 2,
    },
    {
      path: WISH_LIST_PATH,
      component: "DesignProfileQuizPage",
      title: "What’s on your design wish list?",
      button_text: "NEXT",
      alt_button_text: null,
      next: UNIQUE_YARD_PATH,
      previous: YARD_SIZE_PATH,
      selections: {
        landscaping: landscapingOptions,
        home_exterior: exteriorOptions,
      },
      multiple_selections: true,
      required: true,
      drop_down: false,
      header_page: 3,
      completion_number: 5,
      quiz_step_number: 3,
    },
    {
      path: UNIQUE_YARD_PATH,
      component: "DesignProfileQuizPage",
      title:
        "Every yard is unique! What are the unique challenges in your yard?",
      button_text: "NEXT",
      alt_button_text: null,
      next: TIME_FRAME_PATH,
      previous: WISH_LIST_PATH,
      selections: {
        default: uniqueYardOptions,
      },
      multiple_selections: true,
      required: false,
      drop_down: false,
      header_page: 3,
      completion_number: 6,
      quiz_step_number: 4,
    },
    {
      path: TIME_FRAME_PATH,
      component: "DesignProfileQuizPage",
      title: "When do you want to start your project?",
      button_text: "NEXT",
      alt_button_text: null,
      next: BUDGET_PATH,
      previous: UNIQUE_YARD_PATH,
      selections: {
        default: timeFrameOptions,
      },
      multiple_selections: false,
      required: false,
      drop_down: false,
      header_page: 3,
      completion_number: 7,
      quiz_step_number: 5,
    },
    {
      path: BUDGET_PATH,
      component: "DesignProfileQuizPage",
      title: "What is your budget for your outdoor project?",
      subtitle:
        "We know design is just the first piece in a larger project budget. We do our best to design to your budget, and we are transparent about pricing in your design so you’ll see the estimated costs to install the design.",
      button_text: "NEXT",
      alt_button_text: null,
      next: RECOMMENDED_PACKAGE_PATH,
      previous: TIME_FRAME_PATH,
      selections: {
        default: budgetOptions,
      },
      multiple_selections: false,
      required: false,
      drop_down: true,
      header_page: 3,
      completion_number: 8,
      quiz_step_number: 6,
    },
    {
      path: RECOMMENDED_PACKAGE_PATH,
      component: "RecommendedPackagePage",
      title: "We recommend...",
      button_text: "PURCHASE",
      alt_button_text: "Questions? Talk to Our Team",
      next: CREATE_ACCOUNT_PATH,
      previous: BUDGET_PATH,
      header_page: 4,
    },
  ],
};

import {
  ACREAGE_LOT,
  ACREAGE_LOT_WORDPRESS_NAME,
  LARGE_LIFESTYLE,
  LARGE_LOT,
  LARGE_LOT_B,
  LARGE_LOT_WORDPRESS_NAME,
  LotSizeTypes,
  LOWES_LOT,
  LOWES_LOT_WORDPRESS_NAME,
  STANDARD_LIFESTYLE,
  STANDARD_LOT,
  STANDARD_LOT_B,
  STANDARD_LOT_WORDPRESS_NAME,
} from "../../../util/constants/lotSizeTypes";
import {
  BACK_YARD_PACKAGE,
  BACK_YARD_WORDPRESS_NAME,
  BOTANICAL_PACKAGE,
  BOTANICAL_WORDPRESS_NAME,
  CURB_APPEAL_PACKAGE,
  CURB_APPEAL_WORDPRESS_NAME,
  ENTIRE_YARD_STARTER_PACKAGE,
  ENTIRE_YARD_STARTER_WORDPRESS_NAME,
  FRONT_OR_BACKYARD_STARTER_PACKAGE,
  FRONT_OR_BACKYARD_STARTER_WORDPRESS_NAME,
  FRONT_YARD_PACKAGE,
  FRONT_YARD_WORDPRESS_NAME,
  FULL_YARD_PACKAGE,
  FULL_YARD_WORDPRESS_NAME,
  OUTDOOR_TRANSFORMATION_PACKAGE,
  PackageTypes,
  SMALL_SPACE_PACKAGE,
  SMALL_SPACE_WORDPRESS_NAME,
  UBER_PREMIUM_PACKAGE,
  UBER_PREMIUM_WORDPRESS_NAME,
  WHOLE_HOME_WORDPRESS_NAME,
} from "../../../util/constants/packageTypes";

// Builds a link to the package page on the WordPress site for a
// given product.

export function getLinkToPackagePage(
  product: PackageTypes,
  lotSize: LotSizeTypes,
  email?: string,
  isRecommended?: boolean,
  splitToggle?: boolean | null
): string {
  const packageName = getLinkPackageName(product);

  // The botanical package only has 1 lot size
  const lotSizeName =
    packageName === BOTANICAL_WORDPRESS_NAME
      ? STANDARD_LOT_WORDPRESS_NAME
      : getLinkLotSize(lotSize);

  const url = new URL(`${process.env.REACT_APP_WORDPRESS_BASE_URL}`);
  url.pathname = url.pathname.replace(/\/$/, "") + `/${packageName}`;

  url.searchParams.append("lotsize", lotSizeName);
  url.searchParams.append("is-recommended", (!!isRecommended).toString());

  if (splitToggle) {
    if (email) {
      url.searchParams.append("email", email);
    }
    if (isRecommended) {
      const date = new Date();
      const formattedDate = date
        .toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        })
        .replace(/\//g, "-");
      url.searchParams.append("start_time", formattedDate);
    }
  }

  return url.toString();
}

// The package name used in the link does not line up to the one
// that we use in the Stripe packages
export function getLinkPackageName(packageName: PackageTypes): string {
  switch (packageName) {
    case BACK_YARD_PACKAGE:
      return BACK_YARD_WORDPRESS_NAME;
    case BOTANICAL_PACKAGE:
      return BOTANICAL_WORDPRESS_NAME;
    case CURB_APPEAL_PACKAGE:
      return CURB_APPEAL_WORDPRESS_NAME;
    case FRONT_YARD_PACKAGE:
      return FRONT_YARD_WORDPRESS_NAME;
    case FULL_YARD_PACKAGE:
      return FULL_YARD_WORDPRESS_NAME;
    case UBER_PREMIUM_PACKAGE:
      return UBER_PREMIUM_WORDPRESS_NAME;
    case OUTDOOR_TRANSFORMATION_PACKAGE:
      return WHOLE_HOME_WORDPRESS_NAME;
    case SMALL_SPACE_PACKAGE:
      return SMALL_SPACE_WORDPRESS_NAME;
    case FRONT_OR_BACKYARD_STARTER_PACKAGE:
      return FRONT_OR_BACKYARD_STARTER_WORDPRESS_NAME;
    case ENTIRE_YARD_STARTER_PACKAGE:
      return ENTIRE_YARD_STARTER_WORDPRESS_NAME;
  }

  return "";
}

export function getLinkLotSize(lotSize: LotSizeTypes): string {
  switch (lotSize) {
    case ACREAGE_LOT:
      return ACREAGE_LOT_WORDPRESS_NAME;
    case LARGE_LOT:
      return LARGE_LOT_WORDPRESS_NAME;
    case LARGE_LOT_B:
      return LARGE_LOT_WORDPRESS_NAME;
    case STANDARD_LOT:
      return STANDARD_LOT_WORDPRESS_NAME;
    case STANDARD_LOT_B:
      return STANDARD_LOT_WORDPRESS_NAME;
    case STANDARD_LIFESTYLE:
      return STANDARD_LOT_WORDPRESS_NAME;
    case LARGE_LIFESTYLE:
      return LARGE_LOT_WORDPRESS_NAME;
    case LOWES_LOT:
      return LOWES_LOT_WORDPRESS_NAME;
  }

  return "";
}

import {
  Box,
  Container,
  Grid,
  Link,
  makeStyles,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import {
  Facebook,
  Instagram,
  LinkedIn,
  Pinterest,
  Twitter,
} from "@material-ui/icons";
import { YZTypography } from "@yardzen-inc/react-common";
import * as React from "react";
import {
  SegmentClickTypes,
  SegmentFlows,
  useSegment,
} from "../../util/Segment";

export interface YZFooterProps {}

const useStyles = makeStyles((theme: Theme) => ({
  footerRoot: {
    borderTop: `1px solid ${theme.palette.text.primary}`,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },
  footerSocialContainer: {
    "& a": {
      padding: theme.spacing(1),
      display: "block",
    },
    "& svg": {
      color: theme.palette.text.primary,
    },
  },
  footerSocialIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  footerLinksContainer: {
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  footerLink: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0, 2),
    display: "block",
    color: theme.palette.text.primary,
    "&:not(:last-child)": {
      borderRight: `1px solid ${theme.palette.grey[100]}`,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  footerGeoLink: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0, 2),
    display: "block",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
}));

const yzRoute = `${process.env.REACT_APP_WORDPRESS_BASE_URL}`;

const YZFooter: React.FC<YZFooterProps> = () => {
  const segment = useSegment();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const {
    footerRoot,
    footerSocialContainer,
    footerSocialIcon,
    footerLinksContainer,
    footerLink,
    footerGeoLink,
  } = useStyles();

  return (
    <div className={footerRoot}>
      <Container maxWidth="md">
        <Box
          display="flex"
          justifyContent="center"
          className={footerLinksContainer}
          mb={3}
        >
          {getYZLinks()}
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          className={footerSocialContainer}
        >
          {makeSocialLink("https://www.instagram.com/yardzen/", <Instagram />)}
          {makeSocialLink("https://www.pinterest.com/Yardzen/", <Pinterest />)}
          {makeSocialLink(
            "https://www.linkedin.com/company/yardzen/mycompany/",
            <LinkedIn />
          )}
          {makeSocialLink(
            "https://twitter.com/yardzen_outdoor?lang=en",
            <Twitter />
          )}
          {makeSocialLink(
            "https://www.houzz.com/professionals/landscape-architects-and-landscape-designers/yardzen-pfvwus-pf~1706131149/__public",
            <img
              src="https://storage.googleapis.com/yardzen-public-assets/social_icon_black_128px.png"
              className={footerSocialIcon}
              alt="Houzz Icon"
            />
          )}
          {makeSocialLink("https://www.facebook.com/yardzen/", <Facebook />)}
        </Box>
        <Box py={3} pb={5}>
          <YZTypography>© 2025 Yardzen Inc. All Rights Reserved.</YZTypography>
        </Box>
        {getGeoLinks()}
      </Container>
    </div>
  );

  function makeLink(
    title: string,
    url: string,
    className?: string
  ): React.ReactNode {
    return (
      <Link
        variant="body2"
        color="textSecondary"
        className={className ? className : footerLink}
        onClick={() => {
          segment.trackClicked({
            button_name: `Package Detail Footer ${title.trim()}`,
            flow_name: SegmentFlows.SHOP_PACKAGES,
            click_type: SegmentClickTypes.FOOTER,
          });
        }}
        href={url}
        target="_blank"
      >
        {title}
      </Link>
    );
  }

  function makeSocialLink(url: string, icon: JSX.Element): React.ReactNode {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {icon}
      </a>
    );
  }

  function getYZLinks(): React.ReactNode {
    return (
      <>
        {makeLink("ABOUT US", `${yzRoute}/about`)}
        {makeLink("CONTACT US", `${yzRoute}/contactus`)}
        {makeLink("WHY YARDZEN", `${yzRoute}/why-yardzen`)}
        {makeLink("YARDZEN YARDS", `${yzRoute}/yardzen-yards`)}
        {makeLink("GIFT YARDZEN", `${yzRoute}/gift-cards`)}
        {makeLink("FAQ", `${yzRoute}/faq`)}
        {makeLink("FOR CONTRACTORS", `${yzRoute}/contractors`)}
        {makeLink("BUILD WITH YARDZEN", `${yzRoute}/build-with-yardzen`)}
        {makeLink(
          "CONSTRUCTION FINANCING",
          "https://www.sofi.com/refer/yardzen"
        )}
        {makeLink("CAREERS", "https://jobs.lever.co/yardzen")}
        {makeLink(
          "DESIGN CAREERS",
          "https://jobs.lever.co/yardzen/d9aae007-c4bb-4605-b8b9-38dd89058ca8"
        )}
        {makeLink("PRESS", `${yzRoute}/press`)}
        {makeLink("PRIVACY POLICY", `${yzRoute}/privacy-policy`)}
        {makeLink("BEFORE & AFTER", `${yzRoute}/before-after`)}
        {makeLink(
          smDown
            ? "YARDZEN BLOG"
            : "YARDZEN BLOG | LANDSCAPE DESIGN INSPIRATION AND IDEAS",
          `${yzRoute}/blog`
        )}
      </>
    );
  }

  function getGeoLinks(): React.ReactNode {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12} md={3}>
          {makeLink(
            "Phoenix, AZ",
            `${yzRoute}/phoenix-landscape-design`,
            footerGeoLink
          )}
          {makeLink(
            "Los Angeles, CA",
            `${yzRoute}/los-angeles-landscape-design`,
            footerGeoLink
          )}
          {makeLink(
            "Mill Valley, CA",
            `${yzRoute}/mill-valley-landscape-design`,
            footerGeoLink
          )}
          {makeLink(
            "Napa, CA",
            `${yzRoute}/napa-landscape-design`,
            footerGeoLink
          )}
          {makeLink(
            "Oakland, CA",
            `${yzRoute}/oakland-landscape`,
            footerGeoLink
          )}
          {makeLink(
            "Palo Alto, CA",
            `${yzRoute}/palo-alto-landscape-design`,
            footerGeoLink
          )}
          {makeLink(
            "Sacramento, CA",
            `${yzRoute}/sacramento-landscape-design`,
            footerGeoLink
          )}
          {makeLink(
            "San Diego, CA",
            `${yzRoute}/san-diego-landscape-design`,
            footerGeoLink
          )}
        </Grid>
        <Grid item xs={12} md={3}>
          {makeLink(
            "San Jose, CA",
            `${yzRoute}/san-jose-landscape-design`,
            footerGeoLink
          )}
          {makeLink(
            "Thousand Oaks, CA",
            `${yzRoute}/thousand-oaks-landscape-design`,
            footerGeoLink
          )}
          {makeLink(
            "Ventura, CA",
            `${yzRoute}/ventura-landscape-design`,
            footerGeoLink
          )}
          {makeLink(
            "Aurora, CO",
            `${yzRoute}/aurora-landscape-design`,
            footerGeoLink
          )}
          {makeLink(
            "Denver, CO",
            `${yzRoute}/denver-landscape-design`,
            footerGeoLink
          )}
          {makeLink(
            "Lakewood, CO",
            `${yzRoute}/lakewood-landscape-design`,
            footerGeoLink
          )}
          {makeLink(
            "Bridgeport, CT",
            `${yzRoute}/bridgeport-landscape-design`,
            footerGeoLink
          )}
          {makeLink(
            "Greenwich, CT",
            `${yzRoute}/greenwich-landscape-design`,
            footerGeoLink
          )}
        </Grid>
        <Grid item xs={12} md={3}>
          {makeLink(
            "New Canaan, CT",
            `${yzRoute}/new-canaan-landscape-design`,
            footerGeoLink
          )}
          {makeLink(
            "Stamford, CT",
            `${yzRoute}/stamford-landscape-design`,
            footerGeoLink
          )}
          {makeLink(
            "Miami, FL",
            `${yzRoute}/miami-landscape-design`,
            footerGeoLink
          )}
          {makeLink(
            "Palm Beach, FL",
            `${yzRoute}/palm-beach-landscape-design`,
            footerGeoLink
          )}
          {makeLink(
            "Winter Park, FL",
            `${yzRoute}/winter-park-landscape-design`,
            footerGeoLink
          )}
          {makeLink(
            "Atlanta, GA",
            `${yzRoute}/atlanta-landscape-design`,
            footerGeoLink
          )}
          {makeLink(
            "Boston, MA",
            `${yzRoute}/landscape-design-boston`,
            footerGeoLink
          )}
          {makeLink(
            "Cambridge, MA",
            `${yzRoute}/landscape-design-in-cambridge`,
            footerGeoLink
          )}
        </Grid>
        <Grid item xs={12} md={3}>
          {makeLink(
            "Potomac, MD",
            `${yzRoute}/potomac-landscape-design`,
            footerGeoLink
          )}
          {makeLink(
            "Portland, OR",
            `${yzRoute}/portland-landscape-design`,
            footerGeoLink
          )}
          {makeLink(
            "Austin, TX",
            `${yzRoute}/landscape-design-austin`,
            footerGeoLink
          )}
          {makeLink(
            "Dallas, TX",
            `${yzRoute}/dallas-landscape-design`,
            footerGeoLink
          )}
          {makeLink(
            "Arlington, VA",
            `${yzRoute}/arlington-landscape-design`,
            footerGeoLink
          )}
          {makeLink(
            "Bellevue, WA",
            `${yzRoute}/bellevue-landscape-design`,
            footerGeoLink
          )}
          {makeLink(
            "Seattle, WA",
            `${yzRoute}/seattle-landscape-design`,
            footerGeoLink
          )}
          {makeLink(
            "Tacoma, WA",
            `${yzRoute}/tacoma-landscape-design`,
            footerGeoLink
          )}
        </Grid>
      </Grid>
    );
  }
};

export { YZFooter };
export default YZFooter;
